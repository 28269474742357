import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';

const Customers = () => {
  const [customers, setCustomers] = useState([]); // State to store fetched customers

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/v1/user/all-customers');
        // Update customers state with fetched data
        setCustomers(response.data.data.allCustomers);
        console.log("Response:----------", response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchCustomers(); // Call the fetchCustomers function when the component mounts
  }, []); // Empty dependency array to run the effect only once on mount

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 }, // Change field to phoneNumber
    // Add more columns as needed
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <h2 className="heading">Customers</h2>
      <DataGrid
        rows={customers} // Use customers state here
        columns={columns}
        pageSize={5}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default Customers;
